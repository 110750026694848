import {Injectable} from "@angular/core";
import {Resolve} from "@angular/router";
import {Apollo} from "apollo-angular";
import {map, Observable} from "rxjs";
import {StoriesLandingPageData} from "src/app/interfaces/stories/stories-landing-page";
import {GET_STORIES_LANDING_PAGE_DATA} from "src/app/queries/stories/stories-landing-page.query";

@Injectable({
  providedIn: "root",
})
export class StoriesLandingPageResolver implements Resolve<StoriesLandingPageData> {
  constructor(private apollo: Apollo) {}

  resolve(): Observable<StoriesLandingPageData> {
    return this.apollo
      .query({
        query: GET_STORIES_LANDING_PAGE_DATA,
      })
      .pipe(
        map((result): StoriesLandingPageData => {
          const {page: meta, ...page} = result.data;
          return {
            title: "مسابقة المحتوى الإثرائي للطفل",
            data: page,
            meta,
          };
        }),
      );
  }
}
