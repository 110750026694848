import {HttpClientModule} from "@angular/common/http";
import {NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {InMemoryCache} from "@apollo/client/core";
import {APOLLO_OPTIONS, ApolloModule} from "apollo-angular";
import {HttpLink} from "apollo-angular/http";
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from "ng-recaptcha";
import {environment} from "src/environments/environment";

import {AppComponent} from "./app.component";
import {AppRoutingModule} from "./app-routing.module";
import {BreadcrumbComponent} from "./components/shared/breadcrumb/breadcrumb.component";
import {CarouselComponent} from "./components/shared/carousel/carousel.component";
import {CopyrightComponent} from "./components/shared/copyright/copyright.component";
import {FontsShareComponent} from "./components/shared/fonts-share/fonts-share.component";
import {FooterLinksComponent} from "./components/shared/footer-links/footer-links.component";
import {HeroComponent} from "./components/shared/hero/hero.component";
import {LoaderComponent} from "./components/shared/loader/loader.component";
import {NavbarComponent} from "./components/shared/navbar/navbar.component";
import {NewsletterComponent} from "./components/shared/newsletter/newsletter.component";
import {PageComponent} from "./components/shared/page/page.component";
import {PageContentComponent} from "./components/shared/page/page-content/page-content.component";
import {PageFooterComponent} from "./components/shared/page/page-footer/page-footer.component";
import {PageHeaderComponent} from "./components/shared/page/page-header/page-header.component";
import {StoriesLandingAboutComponent} from "./components/stories/pages/stories-landing-page/stories-landing-about/stories-landing-about.component";
import {StoriesLandingCarouselComponent} from "./components/stories/pages/stories-landing-page/stories-landing-carousel/stories-landing-carousel.component";
import {StoriesLandingCtaComponent} from "./components/stories/pages/stories-landing-page/stories-landing-cta/stories-landing-cta.component";
import {StoriesLandingPageComponent} from "./components/stories/pages/stories-landing-page/stories-landing-page.component";
import {StoriesLandingRegulationsComponent} from "./components/stories/pages/stories-landing-page/stories-landing-regulations/stories-landing-regulations.component";
import {StoriesLandingSubjectsComponent} from "./components/stories/pages/stories-landing-page/stories-landing-subjects/stories-landing-subjects.component";
import {StoriesComponent} from "./components/stories/stories.component";
import {FileLinkPipe} from "./pipes/file-link.pipe";
import {FileTypePipe} from "./pipes/file-type.pipe";
import {ImageLinkPipe} from "./pipes/image-link.pipe";

const angular = [BrowserModule, BrowserAnimationsModule, HttpClientModule, FormsModule, ReactiveFormsModule];

const material = [MatInputModule, MatFormFieldModule, MatIconModule, MatButtonModule];

const thirdParties = [ApolloModule, RecaptchaV3Module,];

const directives = [];

const pipes = [ImageLinkPipe, FileLinkPipe, FileTypePipe];

const root = AppComponent;

const globallySharedComponents = [
  CopyrightComponent,
  FooterLinksComponent,
  NewsletterComponent,
  FontsShareComponent,
  LoaderComponent,
  PageComponent,
  PageContentComponent,
  PageHeaderComponent,
  PageFooterComponent,
  HeroComponent,
  BreadcrumbComponent,
  CarouselComponent,
  NavbarComponent,
];

const storiesSharedComponents = [];

const storiesComponents = [
  StoriesComponent,
  StoriesLandingPageComponent,
  StoriesLandingCarouselComponent,
  StoriesLandingAboutComponent,
  StoriesLandingSubjectsComponent,
  StoriesLandingRegulationsComponent,
  StoriesLandingCtaComponent,
];

const components = [root, ...globallySharedComponents, ...storiesSharedComponents, ...storiesComponents];

@NgModule({
  declarations: [...directives, ...pipes, ...components],
  imports: [...angular, ...material, ...thirdParties, AppRoutingModule],
  providers: [
    ...pipes,
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink) {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: environment.graphqlURI,
          }),
        };
      },
      deps: [HttpLink],
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
