import {BreakpointObserver} from "@angular/cdk/layout";
import {DOCUMENT} from "@angular/common";
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import {NavigationEnd, Router} from "@angular/router";
import {Dropdown, Offcanvas} from "bootstrap";
import {filter, fromEvent, ReplaySubject, takeUntil} from "rxjs";
import {PortalContentService} from "src/app/services/portal-content.service";
import {environment} from "src/environments/environment";

enum MenuItemType {
  LINK,
  GROUP,
  FRAGMENT,
}

enum MenuArrowPosition {
  START,
  END,
}

interface MenuItem {
  title: string;
  type: MenuItemType;
  href?: boolean;
  link?: string;
  children?: MenuItem[];
  arrowPosition?: MenuArrowPosition;
  icon?: string;
  isVertical?: boolean;
  // state?: unknown;
}

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit, OnDestroy {
  MenuItemType = MenuItemType;
  MenuArrowPosition = MenuArrowPosition;

  @Input() landing!: boolean;

  menu: MenuItem[] = [
    {title: "الرئيسة", type: MenuItemType.LINK, href: false, link: "/"},
    {
      title: "من نحن",
      type: MenuItemType.GROUP,
      children: [
        {
          title: "من نحن",
          type: MenuItemType.LINK,
          href: true,
          link: `${environment.BASE_URL}/about`,
          icon: "fa-solid fa-person-rays",
        },
        // {
        //   title: "الرؤية",
        //   type: MenuItemType.LINK,
        //   href: false,
        //   link: "/vision-mission",
        //   icon: "fa-solid fa-circle-check",
        // },
        // {
        //   title: "الرسالة",
        //   type: MenuItemType.LINK,
        //   href: false,
        //   link: "/vision-mission",
        //   icon: "fa-regular fa-message",
        // },
        // {
        //   title: "الأهداف",
        //   type: MenuItemType.LINK,
        //   href: false,
        //   link: "/vision-mission",
        //   icon: "fa-solid fa-bullseye",
        // },
        {
          title: "المهام",
          type: MenuItemType.LINK,
          href: true,
          link: `${environment.BASE_URL}/tasks`,
          icon: "fa-solid fa-list-check",
        },
        // {
        //   title: "الأنظمة و اللوائح",
        //   type: MenuItemType.LINK,
        //   href: false,
        //   link: "/laws",
        //   icon: "fa-regular fa-rectangle-list",
        // },
        {
          title: "الإدارات",
          type: MenuItemType.LINK,
          href: true,
          link: `${environment.BASE_URL}/departments`,
          icon: "fa-solid fa-sliders",
        },
        {
          title: "الخطط الإستراتيجية",
          type: MenuItemType.LINK,
          href: true,
          link: `${environment.BASE_URL}/strategic-plans`,
          icon: "fa-solid fa-sliders",
        },
        {
          title: "الدول الأعضاء",
          type: MenuItemType.LINK,
          href: true,
          link: `${environment.BASE_URL}/members`,
          icon: "fa-solid fa-globe",
        },
        {
          title: "المراكز المتخصصة للمكتب",
          type: MenuItemType.LINK,
          href: true,
          link: `${environment.BASE_URL}/centers`,
          icon: "fa-solid fa-map-location-dot",
        },
        {
          title: "المدير العام",
          type: MenuItemType.LINK,
          href: true,
          link: `${environment.BASE_URL}/manager`,
          icon: "fa-solid fa-certificate",
        },
      ],
      arrowPosition: MenuArrowPosition.START,
    },
    {
      title: "البرامج و المشاريع",
      type: MenuItemType.LINK,
      href: true,
      link: `${environment.BASE_URL}/projects`,
      // icon: "",
      // children: [
      //   {
      //     title: "برامج ومشاريع المركز الرئيس (الرياض)",
      //     type: MenuItemType.LINK,
      //     href: false,
      //     link: "/projects",
      //     icon: "",
      //     state: CenterName.Riyadh,
      //   },
      //   {
      //     title: "برامج ومشاريع المركز العربي للبحوث التربوية (الكويت)",
      //     type: MenuItemType.LINK,
      //     href: false,
      //     link: "/projects",
      //     icon: "",
      //     state: CenterName.Kwait,
      //   },
      //   {
      //     title: "برامج ومشاريع المركز العربي للتدريب التربوي (الدوحة)",
      //     type: MenuItemType.LINK,
      //     href: false,
      //     link: "/projects",
      //     icon: "",
      //     state: CenterName.Doha,
      //   },
      //   {
      //     title: "برامج ومشاريع المركز التربوي للغة العربية  (الشارقة)",
      //     type: MenuItemType.LINK,
      //     href: false,
      //     link: "/projects",
      //     icon: "",
      //     state: CenterName.Sharka,
      //   },
      // ],
    },
    {
      title: "المكانة الإقليمية و الدولية",
      type: MenuItemType.GROUP,
      // href: false,
      children: [
        {
          title: "نبذة عن المكانة الإقليمية و الدولية",
          type: MenuItemType.LINK,
          href: true,
          link: `${environment.BASE_URL}/standing`,
          icon: "fa-solid fa-arrows-to-dot",
        },
        {
          title: "اتفاقيات شركات وتعاون إقليمية ودولية",
          type: MenuItemType.LINK,
          href: true,
          link: `${environment.BASE_URL}/agreements`,
          icon: "fa-solid fa-arrows-turn-to-dots",
        },
        {
          title: "المكتب في أرقام",
          type: MenuItemType.LINK,
          href: true,
          link: `${environment.BASE_URL}/numbers`,
          icon: "fa-solid fa-arrow-up-9-1",
        },
        {
          title: "تقارير ودراسات إقليمية ودولية",
          type: MenuItemType.LINK,
          href: true,
          link: `${environment.BASE_URL}/reports`,
          icon: "fa-solid fa-book",
        },
        {
          title: "التعليم 2030",
          type: MenuItemType.LINK,
          href: true,
          icon: "fa-solid fa-school-flag",
          link: "https://sdg4.abegs.org",
        },
      ],
    },
    {
      title: "الفعاليات",
      type: MenuItemType.GROUP,
      // href: false,
      children: [
        {
          title: "رزنامة الفعاليات",
          type: MenuItemType.LINK,
          href: true,
          link: `${environment.BASE_URL}/events`,
          icon: "fa-solid fa-book-open",
        },
        {
          title: "المؤتمرات",
          type: MenuItemType.LINK,
          href: true,
          link: `${environment.BASE_URL}/conferences`,
          icon: "fa-solid fa-school",
        },
        {
          title: "التدريب",
          type: MenuItemType.LINK,
          href: true,
          link: `${environment.BASE_URL}/training`,
          icon: "fa-solid fa-chalkboard-user",
        },
        {
          title: "اللقاءات",
          type: MenuItemType.LINK,
          href: true,
          link: `${environment.BASE_URL}/appointments`,
          icon: "fa-regular fa-handshake",
        },
        {
          title: "ورش العمل",
          type: MenuItemType.LINK,
          href: true,
          link: `${environment.BASE_URL}/workshops`,
          icon: "fa-solid fa-briefcase",
        },
        {
          title: "الندوات",
          type: MenuItemType.LINK,
          href: true,
          link: `${environment.BASE_URL}/seminars`,
          icon: "fa-solid fa-house-laptop",
        },
        {
          title: "الاجتماعات",
          type: MenuItemType.LINK,
          href: true,
          link: `${environment.BASE_URL}/meetings`,
          icon: "fa-solid fa-building",
        },
      ],
    },
    {
      title: "الإصدارات و النشر",
      type: MenuItemType.GROUP,
      // href: false,
      children: [
        {
          title: "الإصدارات",
          type: MenuItemType.LINK,
          href: true,
          link: `${environment.BASE_URL}/publications`,
          icon: "fa-solid fa-book",
        },
        {
          title: "رسالة الخليج العربي",
          type: MenuItemType.LINK,
          href: true,
          link: "https://library.abegs.org/journal/default/index",
          icon: "fa-regular fa-newspaper",
        },
        {
          title: "نشرة التربية",
          type: MenuItemType.LINK,
          href: true,
          link: "https://abegs.store/%D9%86%D8%B4%D8%B1%D8%A9-%D8%AA%D8%B1%D8%A8%D9%8A%D8%A9-%D8%A7%D9%84%D8%B1%D9%82%D9%85%D9%8A%D8%A9/c1581627417",
          icon: "fa-regular fa-paper-plane",
        },
        {
          title: "الدراسات و الأبحاث",
          type: MenuItemType.LINK,
          href: true,
          link: `${environment.BASE_URL}/studies`,
          icon: "fa-solid fa-file-circle-check",
        },
        // {
        //   title: "التقارير و الوثائق",
        //   type: MenuItemType.LINK,
        //   href: false,
        //   link: "/documents",
        //   icon: "fa-regular fa-note-sticky",
        // },
        {
          title: "تأليف الكتب",
          type: MenuItemType.LINK,
          href: true,
          link: `${environment.BASE_URL}/publishing`,
        },
        {
          title: "المصادر المفتوحة",
          type: MenuItemType.LINK,
          href: true,
          link: `${environment.BASE_URL}/sources`,
        },
        // {
        //   title: "المصادر المفتوحة",
        //   type: MenuItemType.GROUP,
        //   href: false,
        //   children: [
        //     {
        //       title: "نشرة التربية الرقمية",
        //       type: MenuItemType.LINK,
        //       href: true,
        //       link: "https://abegs.store/%D9%86%D8%B4%D8%B1%D8%A9-%D8%AA%D8%B1%D8%A8%D9%8A%D8%A9-%D8%A7%D9%84%D8%B1%D9%82%D9%85%D9%8A%D8%A9/c1581627417",
        //       icon: "fa-regular fa-paper-plane",
        //     },
        //     {
        //       title: "التقارير و الوثائق",
        //       type: MenuItemType.LINK,
        //       href: false,
        //       link: "/reports",
        //       icon: "fa-regular fa-newspaper",
        //     },
        //     {
        //       title: "الدراسات و البحوث",
        //       type: MenuItemType.LINK,
        //       href: false,
        //       link: "/studies",
        //       icon: "fa-solid fa-book-atlas",
        //     },
        //     {
        //       title: "التعليم للجميع",
        //       type: MenuItemType.LINK,
        //       href: true,
        //       link: "https://www.abegs.org/sdg4",
        //       icon: "fa-solid fa-book-open-reader",
        //     },
        //     {
        //       title: "قناة اليوتيوب",
        //       type: MenuItemType.LINK,
        //       href: true,
        //       link: "https://www.youtube.com/playlist?list=PLUu4O0oQg4FNrCOdb0EGL6oKmzhtZ_4tP",
        //       icon: "fa-brands fa-youtube",
        //     },
        //     {
        //       title: "منصة المهارات الحيوية",
        //       type: MenuItemType.LINK,
        //       href: true,
        //       link: "https://skills.abegs.org/home",
        //       icon: "fa-solid fa-child-reaching",
        //     },
        //     {
        //       title: "الإنفوجرافيك التعليمي",
        //       type: MenuItemType.LINK,
        //       href: true,
        //       link: "https://www.abegs.org/displayimages",
        //       icon: "fa-solid fa-sitemap",
        //     },
        //   ],
        // },
        {
          title: "الكتب المؤلفة و المترجمة",
          type: MenuItemType.LINK,
          href: true,
          link: "https://abegs.store/",
          icon: "fa-solid fa-book",
        },
      ],
    },
    {
      title: "الخدمات الإلكترونية",
      type: MenuItemType.GROUP,
      // href: false,
      children: [],
    },
    {
      title: "المركز الإعلامي",
      type: MenuItemType.GROUP,
      // href: false,
      children: [
        {
          title: "النشرة البريدية",
          type: MenuItemType.FRAGMENT,
          href: false,
          link: "newsletter",
          icon: "fa-regular fa-newspaper",
          isVertical: true,
        },
        {
          title: "معرض الصور",
          type: MenuItemType.LINK,
          href: true,
          link: `${environment.BASE_URL}/photo-gallery`,
          icon: "fa-solid fa-images",
          isVertical: true,
        },
        {
          title: "قناة التربية",
          type: MenuItemType.LINK,
          href: true,
          link: "https://www.youtube.com/@AbegsOrg",
          icon: "fa-brands fa-youtube",
          isVertical: true,
        },
        {
          title: "الإنفوجرافيك التعليمي",
          type: MenuItemType.LINK,
          href: true,
          link: `${environment.BASE_URL}/infographic-gallery`,
          icon: "fa-solid fa-sitemap",
          isVertical: true,
        },
        {
          title: "وسائل التواصل الإجتماعي",
          type: MenuItemType.FRAGMENT,
          href: false,
          link: "social-media",
          icon: "fa-solid fa-square-share-nodes",
          isVertical: true,
        },
        {
          title: "الأخبار",
          type: MenuItemType.LINK,
          href: true,
          link: `${environment.BASE_URL}/news`,
          icon: "fa-regular fa-newspaper",
          isVertical: true,
        },
        {
          title: "تواصل معنا",
          type: MenuItemType.LINK,
          href: true,
          link: `${environment.BASE_URL}/contact-us`,
          icon: "fa-solid fa-phone-flip",
          isVertical: true,
        },
      ],
    },
  ];

  @ViewChild("searchForm") searchForm!: ElementRef<HTMLFormElement>;
  @ViewChild("offcanvas") offcanvasRef!: ElementRef<HTMLDivElement>;
  @ViewChildren("offcanvasMenu")
  offcanvasMenus!: QueryList<ElementRef<HTMLElement>>;

  isXXLarge!: boolean;
  heightFlag = true;

  searchKeyword = "";
  showSearchInput!: boolean;

  loading!: boolean;

  unsubscribe$ = new ReplaySubject(1);

  constructor(
    private breakpointObserver: BreakpointObserver,
    private cmsService: PortalContentService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.clearOffcanvasStyles();
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(["(min-width: 1400px)"])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        this.isXXLarge = value.breakpoints["(min-width: 1400px)"];
      });

    fromEvent(window, "scroll")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.heightFlag = this.document.documentElement.scrollTop <= 200;
      });

    // this.loadNavBarItems();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: PointerEvent) {
    this.handleSearchFormOnDocumentClick(event);
    this.handleOffcanvasOnDocumentClick(event);
  }

  handleSearchFormOnDocumentClick(event: PointerEvent) {
    const target = event.target as HTMLElement | null;
    if (!target || !this.searchForm || !this.searchForm.nativeElement) return;
    if (!this.searchForm.nativeElement.contains(target)) {
      this.showSearchInput = false;
    }
  }

  handleOffcanvasOnDocumentClick(event: PointerEvent) {
    const target = event.target as HTMLElement | null;
    if (!target || !target.parentElement || !this.offcanvasMenus || !this.offcanvasMenus.length) {
      return;
    }

    this.offcanvasMenus.forEach((elementRef) => {
      if (!target.parentElement) return;
      const element = elementRef.nativeElement;
      if (!element.contains(target)) {
        const dropdownTrigger = element.querySelector(".dropdown-toggle");
        if (!dropdownTrigger) return;
        const dropdownRef = Dropdown.getOrCreateInstance(dropdownTrigger);
        if (dropdownRef) {
          dropdownRef.hide();
        }
      }
    });
  }

  clearOffcanvasStyles() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        this.document.body.removeAttribute("style");
      });
  }

  loadNavBarItems() {
    this.loading = true;
    this.cmsService
      .getNavbarItems()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (items) => {
          const {services} = items;
          this.menu[6].children?.push(
            ...services.map((service) => ({
              title: service.title,
              type: MenuItemType.LINK,
              href: true,
              link: service.link,
            })),
          );
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  onMouseOver(trigger: HTMLAnchorElement, menu: HTMLDivElement) {
    if (!trigger || !menu) return;
    const dropdownRef = Dropdown.getOrCreateInstance(trigger);
    if (dropdownRef) {
      dropdownRef.show();
    }
  }

  onMouseLeave(trigger: HTMLAnchorElement, menu: HTMLDivElement) {
    if (!trigger || !menu) return;
    const dropdownRef = Dropdown.getOrCreateInstance(trigger);
    if (dropdownRef) {
      dropdownRef.hide();
    }
  }

  dismissOffcanvas() {
    if (!this.offcanvasRef || !this.offcanvasRef.nativeElement) return;
    const offcanvas = Offcanvas.getOrCreateInstance(this.offcanvasRef.nativeElement);
    if (offcanvas) {
      offcanvas.hide();
    }
  }

  onSearch() {
    // TODO
    if (this.searchKeyword) {
      this.searchKeyword = "";
      this.showSearchInput = false;
    }
  }
}
