<!-- <div id="publishing-subjects" class="py-5 my-5">
  <div class="container">
    <div class="text-center">
      <h6 class="fw-bold text-primary mt-5">{{ meta.subjectsSectionTitle }}</h6>
      <h6 class="fs-1 fw-bold mt-4 mb-5">{{ meta.subjectsSectionSubtitle }}</h6>
    </div>

    <div class="container-fluid">
      <div class="row justify-content-center g-5">
        <div *ngFor="let subject of subjects" class="col-lg-3 col-md-4 col-sm-8 col-8">
          <div class="card border-0 h-100 shadow-sm rounded-3">
            <div class="card-body p-5">
              <div class="text-light rounded-circle d-flex align-items-center justify-content-center ms-auto">
                <img [src]="subject.image | imageLink" [alt]="subject.title" style="width: 40px; height: 40px" />
              </div>
              <span class="d-block text-center fw-bold text-light-gray fs-6 mt-4">{{ subject.title }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- <section id="stories-subjects" class="py-5">
  <div class="h-100">
    <div class="d-flex justify-content-center align-content-center h-100">
      <div *ngFor="let subject of subjects" class="col-4">
        <img [src]="subject.image | imageLink" [alt]="subject.title" class="img-fluid" style="height: 360px; width: 100%" />
      </div>
    </div>
  </div>
</section> -->
<div id="stories-subjects" class="py-5 my-5">
  <div class="container">
    <div class="text-center">
      <h6 class="fs-1 fw-bold mt-4 mb-5">{{ meta.subjectsSectionTitle }}</h6>
    </div>

    <div class="d-flex justify-content-center">
      <div class="accordion" id="accordionExample" style="width: 80%">
        <div *ngFor="let subject of subjects; let i = index" class="accordion-item">
          <h2 class="accordion-header" [id]="'heading-' + i">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              [attr.data-bs-target]="'#collapse-' + i"
              aria-expanded="true"
              [attr.aria-controls]="'collapse-' + i"
              [ngStyle]="{'background-image': 'url(' + (subject.image | imageLink) + ')'}"
            >
              {{ subject.title }}
            </button>
          </h2>
          <div
            [id]="'collapse-' + i"
            class="accordion-collapse collapse"
            [ngClass]="{show: i === 0}"
            [attr.aria-labelledby]="'heading-' + i"
            data-bs-parent="#accordionExample"
          >
            <div class="p-5 subject-content" [innerHTML]="subject.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
