import {DOCUMENT} from "@angular/common";
import {Component, Inject, OnDestroy} from "@angular/core";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter, ReplaySubject, take, takeUntil} from "rxjs";
import {Title} from "@angular/platform-browser";
import {PageData} from "./interfaces/page-data";
import { AnalyticsService } from "./services/analytics.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnDestroy {
  loading = true;

  unsubscribe$ = new ReplaySubject(1);

  constructor(
    private router: Router,
    private titleService: Title,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private analyticsService: AnalyticsService
  ) {
    this.subscribeToRouterEvents();
    this.subscribeToFragmentChange();
    this.analyticsService.run();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }

  subscribeToRouterEvents() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        this.loading = false;
        window.scrollTo(0, 0);
      });
  }

  subscribeToFragmentChange() {
    this.route.fragment
      .pipe(
        filter((fragment) => !!fragment),
        take(1),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((fragment) => {
        if (!fragment) return;
        const interval = setInterval(() => {
          const element = this.document.getElementById(fragment);
          if (!element) return;
          element.scrollIntoView();
          clearInterval(interval);
        }, 500);
      });
  }
  setPageTitle() {
    let route = this.route;
    while (route.firstChild) {
      route = route.firstChild;
    }

    const {title} = route.snapshot.data["page"] as PageData;
    if (title) {
      this.titleService.setTitle(`${title} | مسابقة المحتوي الإثرائي للطفل`);
    } else {
      this.titleService.setTitle(`مسابقة المحتوي الإثرائي للطفل`);
    }
  }
}
