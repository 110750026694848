import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";

import {StoriesLandingPageComponent} from "./components/stories/pages/stories-landing-page/stories-landing-page.component";
import {StoriesComponent} from "./components/stories/stories.component";
import {StoriesLandingPageResolver} from "./resolvers/stories/stories-landing-page.resolver";

const storiesRoutes: Routes = [
  {path: "", component: StoriesLandingPageComponent, resolve: {page: StoriesLandingPageResolver}},
];

const routes: Routes = [
  {path: "", component: StoriesComponent, children: [...storiesRoutes]},
  {path: "**", redirectTo: ""},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {anchorScrolling: "enabled", onSameUrlNavigation: "reload"})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
