import {Injectable} from "@angular/core";
import {Apollo} from "apollo-angular";
import {map, Observable} from "rxjs";
import {GET_NAVBAR_ITEMS} from "src/app/queries/portal/navbar-query";

import {ContactUsPage} from "../interfaces/portal/contact-us-page";
import {GET_CONTACT_US_PAGE} from "../queries/portal/contact-us-page.query";

@Injectable({
  providedIn: "root",
})
export class PortalContentService {
  constructor(private apollo: Apollo) {}

  getContactUsDetails(): Observable<ContactUsPage> {
    return this.apollo
      .query({
        query: GET_CONTACT_US_PAGE,
      })
      .pipe(
        map((result): ContactUsPage => {
          const contactus = result.data.page;
          return contactus;
        }),
      );
  }

  getNavbarItems() {
    return this.apollo
      .query({
        query: GET_NAVBAR_ITEMS,
      })
      .pipe(
        map((result) => {
          const items = result.data;
          return items;
        }),
      );
  }
}
