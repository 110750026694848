<section id="stories-cta" class="py-5" [ngStyle]="{'background-image': 'url(' + (meta.ctaSectionImage | imageLink) + ')'}">
  <div class="h-100">
    <div class="container text-light d-flex flex-column align-items-center justify-content-center h-100 py-5 text-center">
      <h5 class="fs-1 fw-bold mt-4 mb-5">{{ meta.ctaSectionTitle }}</h5>
      <p class="">{{ meta.ctaSectionContent }}</p>
      <div class="d-flex justify-content-center align-items-center gap-5 mt-5">
        <a class="cta-btn" [href]="meta.applicationFormPdf | fileLink" target="_blank">
          نموذج الترشيح
          <i class="fa-solid fa-download"></i>
        </a>
        <a class="cta-btn" [href]="meta.acknowledgementPdf | fileLink" target="_blank">
          إقرار بالملكية الفكرية
          <i class="fa-solid fa-download"></i>
        </a>
        <a class="cta-btn" [href]="meta.guidelinesPdf | fileLink" target="_blank">
          معايير التقويم
          <i class="fa-solid fa-download"></i>
        </a>
      </div>
    </div>
  </div>
</section>
