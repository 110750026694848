// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  graphqlURI: "https://testserver.abegs.org/cms/graphql",
  assetsURI: "https://testserver.abegs.org/cms/assets",
  serverURI: "https://testserver.abegs.org/portal/api",
  BASE_URL: "https://testserver.abegs.org",
  limit: {
    landingCarousel: 4,
    landingNews: 8,
    landingProjects: 8,

    publications: 12,
    news: 12,
    conferences: 12,
    projects: 12,

    publicationsSidebar: 4,
    reportsSidebar: 4,
    upcomingTraining: 4,
    upcomingWorkshops: 4,
    upcomingMeetings: 4,
    upcomingAppointments: 4,
    upcomingSeminars: 4,

    photosGallery: 15,
    videosGallery: 15,
    infographicsGallery: 15,
  },
  recaptcha: {
    siteKey: "6LcUH_8jAAAAACf_Yk9yDLfukbGeu5-DqdkkDT7i",
    contactUsAction: "portal_contact_us",
    newslettersAction: "portal_newsletters",
  },
  analytics: {
    measurementId: "G-KWMMKVD5S2",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
