<nav
  class="navbar navbar-expand-xxl py-2 px-lg-5 px-sm-2"
  [ngClass]="{
    'py-xxl-5 bg-none absolute-top': landing && heightFlag,
    'bg-white': !landing || !heightFlag,
    'fixed-top': !heightFlag,
    'shadow fade-in-down fast': !heightFlag
  }"
>
  <div class="container">
    <a class="navbar-brand py-0" routerLink="/">
      <!-- Small screens -->
      <img class="d-lg-none logo-sm" src="assets/images/abegs-vertical.svg" height="60px" alt="Logo" />

      <!-- Large screens -->
      <img class="d-none d-lg-inline-block" src="assets/images/abegs-vertical.svg" height="85px" alt="Logo" />

      <span
        class="vr mx-3 d-none d-lg-inline-block"
        style="min-height: 2em; vertical-align: middle; opacity: initial"
      ></span>

      <h5 class="d-none d-lg-inline-block">مسابقة المحتوي الإثرائي للطفل</h5>
    </a>
  </div>
</nav>
