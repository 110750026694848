import {gql} from "apollo-angular";
import {CarouselSlide} from "src/app/interfaces/carousel-slide";
import {
  StoriesLandingPage,
  StoriesLandingPageRegulation,
  StoriesLandingPageSubject,
} from "src/app/interfaces/stories/stories-landing-page";

export const GET_STORIES_LANDING_PAGE_DATA = gql<
  {
    page: StoriesLandingPage;
    carousel: CarouselSlide[];
    regulations: StoriesLandingPageRegulation[];
    subjects: StoriesLandingPageSubject[];
  },
  null
>`
  query {
    page: stories_home_page {
      aboutSectionTitle: about_section_title
      aboutSectionImage: about_section_image {
        id
        filename: filename_download
        type
      }
      aboutSectionContent: about_section_content
      ctaSectionTitle: cta_section_title
      ctaSectionContent: cta_section_content
      ctaSectionImage: cta_section_image {
        id
        filename: filename_download
        type
      }
      acknowledgementPdf: acknowledgement_pdf {
        id
        filename: filename_download
        type
      }
      applicationFormPdf: application_form_pdf {
        id
        filename: filename_download
        type
      }
      guidelinesPdf: guidelines_pdf {
        id
        filename: filename_download
        type
      }
      subjectsSectionTitle: subjects_section_title
      regulationsSectionTitle: regulations_section_title
    }
    carousel: stories_home_slider(filter: {status: {_eq: "published"}}, sort: ["date_created"]) {
      title
      subtitle
      image {
        id
        filename: filename_download
        type
      }
    }
    regulations: stories_home_regulations(filter: {status: {_eq: "published"}}, sort: ["date_created"]) {
      title
      content
    }
    subjects: stories_home_subjects(filter: {status: {_eq: "published"}}, sort: ["date_created"]) {
      title
      image {
        id
        filename: filename_download
        type
      }
      content
    }
  }
`;
